import React from "react";
import { Helmet } from "react-helmet";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "../assets/css/Home.css";
import selenium from "../assets/images/homepage/Selenium-Training Course-in-Chennai - Aimore-Technology.webp";
import powerBi from "../assets/images/homepage/Power-BI-Training -institute-in-Chennai-Placement.webp";
import fullstack from "../assets/images/homepage/Full-Stack-Training -Institute-in-Chennai.webp";
import awsimg from "../assets/images/homepage/Aws-Training-in -Chennai.webp";
import pythonimg from "../assets/images/homepage/Python-Training-in Chennai.webp";
import bestsoftware from "../assets/images/homepage/Best-Software Training-institute -in-Chennai - Aimore-Technology.webp";
import { Link } from "react-router-dom";
import whychoose from "../assets/images/homepage/Why-Choose-Aimore- Technology-for- Upskill.webp";
import bestgateway from "../assets/images/homepage/Best-Software-Training-Course-in Chennai.webp";
import bestSoftware from "../assets/images/homepage/Software-Training in-Chennai-with -Placement.webp";
import upskill from "../assets/images/homepage/Grow-Your-Career -With-Aimore-Technology.webp";
import YourPathtoSuccess from "../assets/images/homepage/IT-Training- Courses-in-Chennai-With-Placement.webp";
import Confidence from "../assets/images/homepage/softwarwe-Training- Courses-in-Chennai-With-Placement.webp";
import numerous from "../assets/images/homepage/Why-Choose -Aimore- Technology For-IT -Training.webp";
import newhome from "../assets/images/best-software-institute-chennai.webp";
import Slider from "react-slick";
import { useRef, useState, useEffect } from "react";
import emailjs from "@emailjs/browser";
import Commonpopup from "../Components/Commonpopup";
function Home() {
  const form = useRef();
  const [urls, setUrls] = useState("");
  const [message, setMessage] = useState("");
  const loadSchema = () => {
    const script = document.createElement("script");
    script.src = "https://www.aimoretechnology.com/homepage.json/";
    script.async = true;
  };

  useEffect(() => {
    setUrls(window.location.href);
    loadSchema();
  }, []);
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_m2qphau",
        "template_fuxwcph",
        form.current,
        "R1muJOWgzl2ngf6ye"
      )
      .then(
        (result) => {
          setMessage("Form Submitted Successfully..");
          console.log(result.text);
        },
        (error) => {
          setMessage("Something went wrong..");
          console.log(error.text);
        }
      );
    form.current.reset();
  };
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div>
      <Helmet>
        <title>Best Software Training Institute In Chennai</title>
        <link rel="canonical" href="https://www.aimoretechnology.com/" />
        <meta
          name="description"
          content="Best & Top Software Training Institutes In Chennai With Placement Guarantee For All The Courses From Aimore Technology – Enroll Now!"
        />
        <meta
          name="keywords"
          content="Software Training Institute in Chennai, Software Course in Chennai , Best Software Training Institute in Chennai, Software Training and placement in chennai, Best Software Training Institute in Chennai, Top Software Training Institute in Chennai"
        />
        <meta property="og:locale" content="en_US" />

        <meta property="og:type" content="website" />

        <meta
          property="og:title"
          content="Software Training Institute in Chennai - Aimore Technology"
        />

        <meta
          property="og:description"
          content="Aimore Technology is the best software training institute in Chennai that offers software courses With 100% placement. Online Training, Flexible Batches."
        />

        <meta property="og:url" content="https://www.aimoretechnology.com/" />

        <meta property="og:site_name" content="Aimore Technology" />

        <meta property="og:updated_time" content="2024-03-30T05:44:16+00:00" />

        <meta
          property="og:image"
          content="https://www.aimoretechnology.com/assets/images/logo/Aimore-logo.svg"
        />

        <meta
          property="og:image:secure_url"
          content="https://www.aimoretechnology.com/assets/images/logo/Aimore-logo.svg"
        />

        <meta property="og:image:width" content="1200" />

        <meta property="og:image:height" content="630" />

        <meta
          property="og:image:alt"
          content="Software training institute in chennai"
        />

        <meta property="og:image:type" content="image/jpeg" />

        <meta name="twitter:card" content="summary_large_image" />

        <meta
          name="twitter:title"
          content="Software Training Institute in Chennai - Aimore Technology"
        />

        <meta
          name="twitter:description"
          content="Aimore Technology is the best software training institute in Chennai that offers software courses With 100% placement. Online Training, Flexible Batches."
        />

        <meta name="twitter:site" content="@AiMORETechnolo1" />

        <meta name="twitter:creator" content="@AiMORETechnolo1" />

        <meta
          name="twitter:image"
          content="https://ehuwt7zd2je.exactdn.com/wp-content/uploads/2023/07/AImore-OG.jpg"
        />

        <meta name="twitter:label1" content="Written by" />

        <meta name="twitter:data1" content="Aimore Technology" />

        <meta name="twitter:label2" content="Time to read" />

        <meta name="twitter:data2" content="Less than a minute" />
      </Helmet>

      <div
        class="modal fade"
        id="staticBackdropNew"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <Commonpopup />
            </div>
          </div>
        </div>
      </div>
      <div class="slider-area banner-style-5 bg-image index-banner">
        <div class="wrapper d-flex align-items-center">
          <div class="container eduvibe-animated-shape content">
            <div className="row">
              <div className="col-md-7">
                <br />
                <h1 className="title">
                  Best Software Training Institute in Chennai with Placement
                  Assistance
                </h1>
                <p>
                  Are you searching for a versatile software training institute
                  in Chennai? AWS courses, Data Science, Selenium, and Python
                  are among the top courses offered in Aimore Technology along
                  with others such as Web Development, Azure DevOps, Cloud
                  Computing, Artificial Intelligence, Machine Learning, Power BI
                  and many more. Enjoy our trainers, practicum, and course
                  content to meet every student's needs when preparing to face
                  the market. For sharp students, we provide Software training
                  programs that can suit the experience level of the trainees.
                  Come, join us and get on to a world of better tomorrow with
                  the most effective software training in Chennai.
                </p>
                <div
                  class="read-more-btn  sal-animate"
                  data-sal-delay="150"
                  data-sal="slide-up"
                  data-sal-duration="800"
                >
                  <Link
                    class="edu-btn"
                    data-bs-target="#staticBackdropNew"
                    data-bs-toggle="modal"
                  >
                    Enroll Now <i class="icon-arrow-right-line-right"></i>
                  </Link>
                </div>
              </div>
              <div className="col-md-5">
                <img
                  src={newhome}
                  alt="best software training in chennai"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container eduvibe-animated-shape">
        <div class="row py-5">
          <div class="col-lg-12">
            <div
              class="section-title text-center sal-animate"
              data-sal-delay="150"
              data-sal="slide-up"
              data-sal-duration="800"
            >
              <h2 class="title">Our Trending Courses</h2>
            </div>
          </div>
        </div>
        <div class="row eduvibe-about-one-service g-5 py-5">
          <div
            class="col-md-4 sal-animate"
            data-sal-delay="150"
            data-sal="slide-up"
            data-sal-duration="800"
          >
            <div class="service-card service-card-3 text-left shape-bg-1 bg-grey">
              <div class="inner">
                <div class="icon">
                  <img src={selenium} alt="selenium" className="img-fluid" />
                </div>
                <div class="content">
                  <h6 class="title">Selenium Training</h6>
                  <p class="description">
                    Want to be a master in Selenium? Then contact Aimore
                    Technology, the best Selenium Training Institute in Chennai.
                    Immerse in the most extensive training courses, excel in
                    automation testing services and enhance your prospects now.
                  </p>
                  <div class="read-more-btn">
                    <Link
                      to="/selenium-training-in-chennai/"
                      className="btn-transparent sm-size heading-color"
                    >
                      Learn More <i class="icon-arrow-right-line-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="col-md-4 sal-animate sal-animate"
            data-sal-delay="200"
            data-sal="slide-up"
            data-sal-duration="800"
          >
            <div class="service-card service-card-3 text-left shape-bg-2 bg-grey">
              <div class="inner">
                <div class="icon">
                  <img src={awsimg} alt="aws-training" className="img-fluid" />
                </div>
                <div class="content">
                  <h6 class="title">AWS Training</h6>
                  <p class="description">
                    People can enhance their AWS skills at Aimore Technology
                    from AWS trainers who have adequate knowledge in this area.
                    Let's explore the features of each option: distance
                    e-learning, live online training, and corporate training
                    provide training including guaranteed job positions.
                  </p>
                  <div class="read-more-btn">
                    <Link
                      to="/aws-training-in-chennai/"
                      className="btn-transparent sm-size heading-color"
                    >
                      Learn More <i class="icon-arrow-right-line-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="col-md-4 sal-animate"
            data-sal-delay="250"
            data-sal="slide-up"
            data-sal-duration="800"
          >
            <div class="service-card service-card-3 text-left shape-bg-3 bg-grey">
              <div class="inner">
                <div class="icon">
                  <img src={powerBi} alt="powerBi" className="img-fluid" />
                </div>
                <div class="content">
                  <h6 class="title">Power BI Training</h6>
                  <p class="description">
                    Microsoft Power BI course aids you in mastering Advanced
                    Business Intelligence Tools and concepts to measure and
                    understand data representations. Knowledge areas about Power
                    BI Basic, Power BI Desktop, Power BI Architecture, Power BI
                    Service, Power BI DAX, Power BI Apps, Power BI Report etc.
                  </p>
                  <div class="read-more-btn">
                    <Link
                      to="/power-bi-training-in-chennai/"
                      className="btn-transparent sm-size heading-color"
                    >
                      Learn More <i class="icon-arrow-right-line-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="col-md-4 sal-animate"
            data-sal-delay="250"
            data-sal="slide-up"
            data-sal-duration="800"
          >
            <div class="service-card service-card-3 text-left shape-bg-3 bg-grey">
              <div class="inner">
                <div class="icon">
                  <img
                    src={pythonimg}
                    alt="python-training"
                    className="img-fluid"
                  />
                </div>
                <div class="content">
                  <h6 class="title">Python Training</h6>
                  <p class="description">
                    Step up your knowledge with Python Training in Chennai with
                    Aimore Software training institute. Become a student and
                    learn how to build a solid foundation in Python programming
                    usable in numerous possibilities.
                  </p>
                  <div class="read-more-btn">
                    <Link
                      to="/python-training-in-chennai/"
                      className="btn-transparent sm-size heading-color"
                    >
                      Learn More <i class="icon-arrow-right-line-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="col-md-4 sal-animate"
            data-sal-delay="250"
            data-sal="slide-up"
            data-sal-duration="800"
          >
            <div class="service-card service-card-3 text-left shape-bg-3 bg-grey">
              <div class="inner">
                <div class="icon">
                  <img
                    src={fullstack}
                    alt="python-training"
                    className="img-fluid"
                  />
                </div>
                <div class="content">
                  <h6 class="title">Full Stack Training</h6>
                  <p class="description">
                    Get started practising Full Stack Development by choosing to
                    learn at Aimore Technology in Chennai and become a commander
                    of full-stack development. Connect with us to get a live
                    practical experience with our professional Trainers.
                  </p>
                  <div class="read-more-btn">
                    <Link
                      to="/full-stack-developer-course-in-chennai/"
                      className="btn-transparent sm-size heading-color"
                    >
                      Learn More <i class="icon-arrow-right-line-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-md-4 sal-animate"
            data-sal-delay="250"
            data-sal="slide-up"
            data-sal-duration="800"
          >
            <div class="service-card service-card-3 text-left shape-bg-3 bg-grey">
              <div class="inner">
                <div class="icon">
                  <img
                    src={bestsoftware}
                    alt="best-software-training"
                    className="img-fluid"
                  />
                </div>
                <div class="content">
                  <h6 class="title">Software Training Course</h6>
                  <p class="description">
                    Learn the best of technologies through our top-rated
                    institute and get expert guidance on advanced tools. Our
                    syllabus is structured to fulfill and align with current
                    standards and expectations for credit recovery for both new
                    and higher-credit students. Unlock your potential today!
                  </p>
                  <div class="read-more-btn">
                    <Link
                      to="/best-software-training-institute-in-chennai/"
                      className="btn-transparent sm-size heading-color"
                    >
                      Learn More <i class="icon-arrow-right-line-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row py-5">
          <div className="col-md-12 text-center">
            <Link
              class="edu-btn"
              data-bs-target="#staticBackdropNew"
              data-bs-toggle="modal"
            >
              To Know More Course Details
              <i class="icon-arrow-right-line-right"></i>
            </Link>
          </div>
        </div>
      </div>

      <div class="eduvibe-home-five-progress edu-service-area edu-section-gap bg-image">
        <div class="container eduvibe-animated-shape">
          <div class="row">
            <div class="col-lg-12">
              <div
                class="section-title text-center sal-animate py-5"
                data-sal-delay="150"
                data-sal="slide-up"
                data-sal-duration="800"
              >
                <h2 class="title">
                  Get Ready To Master Your Career With Aimore's
                  <br />
                  Professional Mentorship and World Classic Practical Training!
                </h2>
                <p className="py-3 ">
                  Aimore is the well-reputed software training and placement
                  Institutes in Chennai, driven by IT professionals Trainers who
                  have experience handling MNC organizations for more than 13
                  years. Proud of the in-person training characteristic, we make
                  sure the students get actual practice with up-to-date
                  technologies. Our Software training courses are delivered by
                  well-experienced instructors, most of whom come from some
                  leading multinationals in the world, thus bridging the gap
                  between the academic and the real corporate world. Aimore
                  connects the theoretical knowledge that you may acquire
                  throughout the months within an academic curriculum and the
                  practical that students require by providing the specific
                  tools to be effective in the technological world of the
                  twenty-first century. Come and change the way you experience
                  professional development – our programmes are all about
                  achieving real results and making you more employable.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 section-title">
              <h2 className="title">
                Why Do You Choose Aimore's Software Training Institute In
                Chennai?
              </h2>
              <br />
              <p>
                Attending a Software Training Institute in Chennai offers a
                varied and essential choice of a vibrant IT ecosystem with great
                training institutes and many job openings. Chennai is a leading
                place where many IT businesses, start-ups, and enterprises are
                located whose recruitment preference is for experienced
                professionals in the Software Training Institute, making it a
                major hub of the IT industry. Here’s why Aimore is the preferred
                choice for thousands of aspiring IT professionals:
              </p>
              <p>
                <b>Extensive Range of Tailor-Made Courses</b>: In Aimore, we
                also consider differences in students and that learning is a
                distinct process for each learner. That is why today we present
                over one hundred unique courses, aiming at developing different
                skills and designed for each student’s dream job. If you have
                never been into technology and wish to get into the market, you
                are at the right place. We have the right course for you. Or
                maybe you are already in the technology field, and you need to
                upgrade yourself. We also offer the perfect course for you. We
                maintain the most up-to-date course offering over the current
                market demands, so you get the best skills you need to succeed.
              </p>
              <p>
                <b> Expert Real-Time Tutors</b>: Our strength is in the team of
                individuals we recruit to ensure the efficient and best
                performance of the company. All our expert tutors consist of
                over 50 personnel who are experienced staff from various
                multinational firms, hence, real-life experience is incorporated
                into the courses offered. Our tutors excel in their respective
                fields of study and always bring out the best in you. Besides,
                they have personalized class instructions, making difficult
                courses easy to understand and preparing you for the industry by
                the time you are through.
              </p>
              <p>
                <b> Hands-On Learning Experience</b>: Our company is in line
                with this by practising what we teach. That is why we include
                more than one hundred practical workshops in teaching a subject
                throughout the course. We employ a hands-on direction strategy
                that makes it possible for students not only to hear the
                theories but also apply the same in real experiences. These
                sessions are highly real-life-like exercises whereby you get the
                experience of the corporate world and the strength to handle
                actual-world challenges.
              </p>
              <p>
                <b> Proven Track Record of Successful Career Launches</b>: In
                this case, the achievements of the University are pegged on the
                achievements of the learners. This means that Aimore has
                established itself as an institution that is capable of
                preparing learners and sending them to the workplace as
                professionals. Given the fact that this institution has been
                able to start over 5,500 learners in their respective careers.
                Our elaborate training curriculum, accredited by industry
                professionals, coupled with our enabling internship and job
                placement system assures you the best opportunity for an ideal
                placement in the cut-throat competitive tech workplace.
              </p>
              <p>
                <b> Access to Learning Resources</b>: Aimore is your door to a
                great universe of learning. Here, you are covered with a rich
                feeding of learning materials to study and practice sessions you
                could engage in. If you want, you can even attach to the
                interactive online community we have. This is the kind of
                resource I would like to suggest to you to apply for solid
                reinforcement of your knowledge and preparations for the exam.
              </p>
              <p>
                <b> Commitment to Sustainability</b>: We at Aimore are dedicated
                to operating the store with consideration for environmental
                sustainability and ecology. Our Software training institutes are
                conceptualized with the sustainability component to minimize our
                negative environmental impacts. Buying Aimore's ethically
                manufactured and creatively designed products, there is a sense
                of satisfaction that you are moving towards a more sustainable
                world.
              </p>
              <p>
                <b>Student Success Stories</b>: The true accomplishment of a
                training institute shall be based on the success of its
                students. Among various feathers in our cap, Aimore is proud of
                its alumni stories. A lot of our students have been aligned with
                higher positions in their workplace and progressed
                professionally with their certifications. These accreditations
                stand as testimony to the effectiveness of our Software Training
                in Chennai.
              </p>
              <p>
                <b>Customer Satisfaction</b>: Training of Aimore is highly
                evaluated by the students as it has surpassed the expectations
                of the students. We are proud of the levels of satisfaction
                shown by our students, proof that the training quality is
                unquestionably high.
              </p>
              <p>
                <b>Commitment to Diversity and Inclusion</b>: Aimore values
                diversity and the inclusion of different people. We create
                classrooms to fit learners with different backgrounds and from
                different walks of life. We are encouraging an inclusive
                environment for teamwork and a respectful attitude. It is one of
                our mottos and objectives to provide equal opportunities for all
                people.
              </p>
              <p>
                <b> Years of Industry Expertise</b>: After more than 12 years of
                service in the industry, Aimore Training Institution has
                attained a reputable position in offering software training and
                placement services. Success in training is mostly informed by an
                understanding of the industry's needs and expectations, and that
                is something that we possess on a fundamental level. Ready for
                the present and the future of the computer technology industry,
                that is what we make you.
              </p>
              <p>
                <b>Delighted Learners</b>: More than 13,000 learners and
                counting are happy to be a part of this community. Let me
                endorse the word from those who had been trained by us; and
                whose success speaks volumes of the effectiveness of our
                training. Aimore works towards the creation of a culture that is
                learner-centered by providing an environment that supports
                learning and fosters relationships.
              </p>
              <p>
                <b>Strategic Locations</b>: To our students, Accessibility and
                convenience are key essentials that they cannot live without
                being frustrated. That is why we have facilities in three
                tactical positions for your convenience and access to our
                premier training academies. Each institute has well-equipped
                facilities to ensure comfortable teaching and learning processes
                abound.
              </p>
              <p>
                <b> Innovative Teaching Methods</b>: By using modern and
                creative approaches to teaching, the importance of learning
                activity becomes apparent. Be it a democratic classroom setting
                activity or structured cooperative learning, simulation work,
                role play, real-time project and case solve studies etc we use
                many ways, so that not only do you learn but also enjoy.
              </p>
              <p>
                <b> Industry Recognition</b>: This is so because the company has
                been in the industry for quite some time and this will imply
                that it has provided its clients with quality training like that
                of Aimore. Experts trained at Aimore possess skills and full
                understanding that are cherished by many employers not only
                within Chennai but in other parts of India as well. There is a
                great demand for our institute’s certifications in the job
                market. , through which you can spend time and explore new
                opportunities and a range of work.
              </p>
            </div>
            <div className="col-md-6">
              <img src={whychoose} alt="why-choose-aimore" />
            </div>
          </div>
        </div>
      </div>

      <div class="eduvibe-about-two-award edu-winner-area edu-section-gap bg-color-white">
        <div class="container eduvibe-animated-shape">
          <div class="row">
            <div class="col-lg-12">
              <div
                class="section-title text-center sal-animate"
                data-sal-delay="150"
                data-sal="slide-up"
                data-sal-duration="800"
              >
                <h2 class="title">Why You choose Us</h2>
              </div>
            </div>
          </div>
          <div class="row g-5 mt--20">
            <div
              class="col-lg-4 col-md-4 col-12 sal-animate"
              data-sal-delay="150"
              data-sal="slide-up"
              data-sal-duration="800"
            >
              <div class="edu-winner-card">
                <div class="inner">
                  <div class="content">
                    <h1>
                      <i class="ri-vip-crown-fill"></i>
                    </h1>
                    <h2 class="title mb-3">12 Years Of Experience</h2>
                    <p>
                      Aimore Technology Started Its Journey In 2010 As A
                      Software Training Institute in Chennai. Since Our Journey
                      Started We have offered 150+ Different Types Of Software
                      Courses Like Oracle, Web Development, Linux, Selenium,
                      Unix, Java, Dot Net, Cloud Computing, Data Science, AWS,
                      And More…
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-lg-4 col-md-4 col-12 sal-animate"
              data-sal-delay="150"
              data-sal="slide-up"
              data-sal-duration="800"
            >
              <div class="edu-winner-card">
                <div class="inner">
                  <div class="content">
                    <h1>
                      <i class="ri-emotion-happy-line"></i>
                    </h1>
                    <h2 class="title mb-3">13,000+ Happy Students</h2>
                    <p>
                      We Trained Over 13,000+ Students To Reach Their Career
                      Goals. Also, We Not Only Help Them To Learn About The
                      Software But Also Prepare Them To Face The Interview With
                      Good Attitude And Knowledge. We Have Provided 100% Results
                      To The Students Who Are Learning In Aimore Technology With
                      Placements In Top MNCs.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-lg-4 col-md-4 col-12 sal-animate"
              data-sal-delay="150"
              data-sal="slide-up"
              data-sal-duration="800"
            >
              <div class="edu-winner-card">
                <div class="inner">
                  <div class="content">
                    <h1>
                      <i class="ri-thumb-up-line"></i>
                    </h1>
                    <h2 class="title mb-3">100% Placement Guaranteed</h2>
                    <p>
                      Studying In An Institute, People Always Come Up With Two
                      Reasons In Their Mind. The First Thing Is To gain
                      knowledge Of The Respective Courses, And The Second One Is
                      Obviously, To Get The Placements In Top Companies As Per
                      CTC Standards. Aimore Technology Is a Stop Solution, To
                      Gain Knowledge And Placement At The Time.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-lg-4 col-md-4 col-12 sal-animate"
              data-sal-delay="150"
              data-sal="slide-up"
              data-sal-duration="800"
            >
              <div class="edu-winner-card">
                <div class="inner">
                  <div class="content">
                    <h1>
                      <i class="ri-terminal-window-line"></i>
                    </h1>
                    <h2 class="title mb-3">Real-Time & Experienced Faculty</h2>
                    <p>
                      At Aimore Technology, You Will Learn From Experts Who Work
                      On Top-Level MNC Companies Dedicated To Sharing Their
                      Knowledge With Their Learners To Achieve Their Goals.
                      Everyone Is Personally Mentored By Industry Experts.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-lg-4 col-md-4 col-12 sal-animate"
              data-sal-delay="150"
              data-sal="slide-up"
              data-sal-duration="800"
            >
              <div class="edu-winner-card">
                <div class="inner">
                  <div class="content">
                    <h1>
                      <i class="ri-service-line"></i>
                    </h1>
                    <h2 class="title mb-3">100% Legible Certificate</h2>
                    <p>
                      Once The Candidate Finished The Course. The Candidates
                      Should Get Their Respective Course Certificates Provided
                      By Aimore Technology. You Can Use The Certificate
                      Anywhere. The Certificate Is Legit You Can Use It For
                      Global Purposes.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-lg-4 col-md-4 col-12 sal-animate"
              data-sal-delay="150"
              data-sal="slide-up"
              data-sal-duration="800"
            >
              <div class="edu-winner-card">
                <div class="inner">
                  <div class="content">
                    <h1>
                      <i class="ri-price-tag-3-line"></i>
                    </h1>
                    <h2 class="title mb-3">
                      Affordable Fees With Great Quality
                    </h2>
                    <p>
                      Aimore Technology, Our Motto is Quality Training at
                      Affordable Fees. “Your Great Goals Come With Fewer Fees".
                      Also, Our Students say, we are the software training
                      institute in Chennai.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-lg-4 col-md-4 col-12 sal-animate"
              data-sal-delay="150"
              data-sal="slide-up"
              data-sal-duration="800"
            >
              <div class="edu-winner-card">
                <div class="inner">
                  <div class="content">
                    <h1>
                      <i class="ri-questionnaire-line"></i>
                    </h1>
                    <h2 class="title mb-3">
                      Interview Preparation Help By Trainers
                    </h2>
                    <p>
                      Aimore Technology, Our Motto is Quality Training at
                      Affordable Fees. “Your Great Goals Come With Fewer Fees".
                      Also, Our Students say, we are the software training
                      institute in Chennai.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-lg-4 col-md-4 col-12 sal-animate"
              data-sal-delay="150"
              data-sal="slide-up"
              data-sal-duration="800"
            >
              <div class="edu-winner-card">
                <div class="inner">
                  <div class="content">
                    <h1>
                      <i class="ri-timer-line"></i>
                    </h1>
                    <h2 class="title mb-3">Study at Your Own Pace</h2>
                    <p>
                      Enhance Your Professional Journey by Acquiring In-Demand
                      Skills
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-lg-4 col-md-4 col-12 sal-animate"
              data-sal-delay="150"
              data-sal="slide-up"
              data-sal-duration="800"
            >
              <div class="edu-winner-card">
                <div class="inner">
                  <div class="content">
                    <h1>
                      <i class="ri-timer-line"></i>
                    </h1>
                    <h2 class="title mb-3">Flexible & Convenient Timings</h2>
                    <p>
                      One Of The Best Things about studying At Aimore Technology
                      Is That You Can Learn The Course at your Comfortable Time.
                      It Will Be A Relief For Professionals Who Manage Their
                      Time.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row py-3">
            <div className="col-md-12 text-center">
              <Link
                class="edu-btn"
                data-bs-target="#staticBackdropNew"
                data-bs-toggle="modal"
              >
                GET STARTED <i class="icon-arrow-right-line-right"></i>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div class="edu-service-area service-wrapper-3">
        <div class="about-us-two-service-wrapper edu-section-gapTop bg-image position-relative">
          <div class="eduvibe-about-us-two-service ">
            <div class="container eduvibe-animated-shape">
              <div class="row eduvibe-about-one-service g-5 mt--20">
                <div
                  class="col-md-6  sal-animate"
                  data-sal-delay="150"
                  data-sal="slide-up"
                  data-sal-duration="800"
                >
                  <div class="service-card service-card-3 text-left shape-bg-1">
                    <div class="inner">
                      <div class="content">
                        <h3 class="title">
                          First Step towards Achieving successful Deals in the
                          IT Industry
                        </h3>
                        <p class="description">
                          In Aimore Technology, work offers possible features of
                          various kinds of activities so that the candidate can
                          get all the necessary knowledge which will help them
                          in building their career. The best software IT
                          training institute in Chennai also helps aspirants
                          build a successful career by letting them know what
                          they need to do next on the path to success. They
                          provide employment options for people who would like
                          to be employed on their own agreed terms. We are
                          extremely competent in ascertaining what sort of
                          candidate they are and aiding this candidate get off
                          to a first-rate start by directing him or her with
                          different courses that are appropriate specifically
                          for this certain candidate.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="col-md-6  sal-animate"
                  data-sal-delay="150"
                  data-sal="slide-up"
                  data-sal-duration="800"
                >
                  <img
                    src={bestgateway}
                    alt="Gateway to Success to Enter in the IT Industry"
                  />
                </div>
                <div
                  class="col-md-6  sal-animate"
                  data-sal-delay="150"
                  data-sal="slide-up"
                  data-sal-duration="800"
                >
                  <img
                    src={bestSoftware}
                    alt="Software Training Institute in Chennai with Placements"
                  />
                </div>
                <div
                  class="col-md-6  sal-animate"
                  data-sal-delay="150"
                  data-sal="slide-up"
                  data-sal-duration="800"
                >
                  <div class="service-card service-card-3 text-left shape-bg-1">
                    <div class="inner">
                      <div class="content">
                        <h3 class="title">
                          Get over the hurdle with a top-notch IT software
                          training institute in Chennai
                        </h3>
                        <p class="description">
                          Aimore offers various courses such as web designing,
                          Java, Python, Selenium, and various other courses as
                          well. These courses are offered to any individual,
                          whether they possess special skills or have a
                          different background. Aimore also ensures that the
                          training is done professionally for the students who
                          wish to learn before joining the industries. In light
                          of this, the teaching and learning style that Aimore
                          proposed to follow is that of active learning. This is
                          advantageous to candidates as they are a part of the
                          process, therefore helping them remember the topics
                          better. They also pay individual attention to every
                          student to enable him/her to master the education
                          needed to excel in his/her area of discipline at the
                          school.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="col-md-6  sal-animate"
                  data-sal-delay="150"
                  data-sal="slide-up"
                  data-sal-duration="800"
                >
                  <div class="service-card service-card-3 text-left shape-bg-1">
                    <div class="inner">
                      <div class="content">
                        <h3 class="title">
                          Enhance Your Knowledge with the Best Software Training
                          Institute
                        </h3>
                        <p class="description">
                          Aimore is a well-known IT training Institute located
                          in Chennai and has been providing training for over 12
                          years. We provide various options concerning level and
                          type, including vocational, certification, and degree
                          programs. We have various course categories available
                          as Classroom and also Online courses. There is a vast
                          catalog of certifications that one can opt for which
                          includes Oracle, Data Science, Azure, AWS, and DevOps
                          among others. For teaching, our team includes highly
                          qualified faculty members with extensive years of
                          experience more than 12 years in the teaching
                          profession and are tech-savvy too. Here at our
                          university, the final semester offers real-life
                          exposure to the students under the guidance of expert
                          trainers from major companies such as CTS, TCS, etc.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="col-md-6  sal-animate"
                  data-sal-delay="150"
                  data-sal="slide-up"
                  data-sal-duration="800"
                >
                  <img
                    src={upskill}
                    alt="Up-skill yourself with the best It software training institute in Chennai"
                  />
                </div>
                <div
                  class="col-md-6  sal-animate"
                  data-sal-delay="150"
                  data-sal="slide-up"
                  data-sal-duration="800"
                >
                  <img
                    src={YourPathtoSuccess}
                    alt="Your Path to Success: Best Software Training Institute equipped with 100% placements."
                  />
                </div>
                <div
                  class="col-md-6  sal-animate"
                  data-sal-delay="150"
                  data-sal="slide-up"
                  data-sal-duration="800"
                >
                  <div class="service-card service-card-3 text-left shape-bg-1">
                    <div class="inner">
                      <div class="content">
                        <h3 class="title">
                          Your Path to Success: Best Software Training Institute
                          equipped with 100% placements.
                        </h3>
                        <p class="description">
                          Aimore Technology is currently considered the
                          fastest-growing institute that offers numerous courses
                          for students and working people. All the courses
                          offered have been developed over time in line with
                          current industry standards and demands. We have got
                          professional and highly qualified trainers from the
                          industry, which is why we always make sure that our
                          students are equipped with all the new skills that
                          they need. The student placement office is very
                          supportive and ensures the candidates are quickly
                          placed in their ideal jobs as soon as they complete
                          their course. While most activity in the recruitment
                          process can be quite standard and mundane, we take it
                          as a victory whenever a candidate receives a placement
                          at a company they have always wanted to work for.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="col-md-6  sal-animate"
                  data-sal-delay="150"
                  data-sal="slide-up"
                  data-sal-duration="800"
                >
                  <div class="service-card service-card-3 text-left shape-bg-1">
                    <div class="inner">
                      <div class="content">
                        <h3 class="title">
                          Experience a new level With the No. 1 Software
                          Training Institute.
                        </h3>
                        <p class="description">
                          It is the leading IT Software Training Institute in
                          Chennai for the Best Software Training for experienced
                          and freshers. They cover various courses like AWS,
                          Java, Python, Data Science, Power BI, AI, ML, etc .
                          Their faculties give input to students on how they can
                          best compare the knowledge and skills which they have
                          acquired in various fields. In addition, they also
                          offer workshops that assist in the improvement of
                          skills that are known as soft skills such as time
                          management skills, leadership skills and other skills
                          in group processes.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="col-md-6  sal-animate"
                  data-sal-delay="150"
                  data-sal="slide-up"
                  data-sal-duration="800"
                >
                  <img
                    src={numerous}
                    alt="Numerous Advantages of Joining Aimore Technology"
                  />
                </div>
                <div
                  class="col-md-6  sal-animate"
                  data-sal-delay="150"
                  data-sal="slide-up"
                  data-sal-duration="800"
                >
                  <img
                    src={Confidence}
                    alt="Confidence for a New Career in Aimore"
                  />
                </div>
                <div
                  class="col-md-6  sal-animate"
                  data-sal-delay="150"
                  data-sal="slide-up"
                  data-sal-duration="800"
                >
                  <div class="service-card service-card-3 text-left shape-bg-1">
                    <div class="inner">
                      <div class="content">
                        <h3 class="title">
                          Confidence for a New Career in Aimore
                        </h3>
                        <p class="description">
                          This choice is to select the path to success when it
                          comes to the naming of the company which chose Aimore.
                          Considering a list of our course offerings, highly
                          skilled tutors, down-to-earth and real-life tutor
                          approach and outstanding performance proved year in
                          and year out, we promise success. Join the multitude
                          of happy professionals with numerous accomplishments
                          who were once like you and sought assistance from
                          Aimore. For that, you need only one call to Aimore –
                          let the countdown for success begin!
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row py-5">
                <div className="col-md-12 text-center">
                  <Link
                    class="edu-btn"
                    data-bs-target="#staticBackdropNew"
                    data-bs-toggle="modal"
                  >
                    To Know More About Us
                    <i class="icon-arrow-right-line-right"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="testi-section py-5">
        <div className="container">
          <div className="row section-title py-5">
            <div className="col-md-8 offset-md-2 client-testimonials">
              <h1 className="title text-center">
                What Do People Say About Us?
              </h1>
              <h4 className="text-center py-2">
                Trusted by Thousands of Students and Tutors
              </h4>
              <Slider {...settings} className="py-5">
                <div className="text-center py-2">
                  <h1>
                    <i class="ri-user-line"></i>
                  </h1>
                  <h1>
                    <b>Kiran Rajesh</b>
                  </h1>
                  <p>
                    <i>
                      Aimore Technologies is a very good place to learn IT
                      software courses and Placement. Trainers are very
                      professional and very expert in SQL, UNIX, ETL and
                      Scripting Languages. I suggest Aimore Technologies for
                      Tester, Developer and supporting role for the IT Sector.
                      Thank You
                    </i>
                  </p>
                  <div className="text-center start-rating">
                    <div className="d-flex justify-content-center">
                      <span>
                        <i class="ri-star-fill"></i>
                      </span>
                      <span>
                        <i class="ri-star-fill"></i>
                      </span>
                      <span>
                        <i class="ri-star-fill"></i>
                      </span>
                      <span>
                        <i class="ri-star-fill"></i>
                      </span>
                      <span>
                        <i class="ri-star-fill"></i>
                      </span>
                    </div>
                  </div>
                </div>

                <div className="text-center py-2">
                  <h1>
                    <i class="ri-user-line"></i>
                  </h1>
                  <h1>
                    <b>Vinodh</b>
                  </h1>
                  <p>
                    <i>
                      If you need to start your career in the IT Sector. I would
                      prefer Aimore technology. One of the best institutes to
                      learn. The staff are very kind and supportive and in
                      giving guidance. I have learned queries from Prabhu and he
                      covered all topics. Placement trainings are given by Gobi,
                      It's very helpful to attend the Interviews in a short
                      period of time.
                    </i>
                  </p>
                  <div className="text-center start-rating">
                    <div className="d-flex justify-content-center">
                      <span>
                        <i class="ri-star-fill"></i>
                      </span>
                      <span>
                        <i class="ri-star-fill"></i>
                      </span>
                      <span>
                        <i class="ri-star-fill"></i>
                      </span>
                      <span>
                        <i class="ri-star-fill"></i>
                      </span>
                      <span>
                        <i class="ri-star-fill"></i>
                      </span>
                    </div>
                  </div>
                </div>

                <div className="text-center py-2">
                  <h1>
                    <i class="ri-user-line"></i>
                  </h1>
                  <h1>
                    <b>Revathi</b>
                  </h1>
                  <p>
                    <i>
                      Excellent training institute in Chennai. The way of
                      coaching is extraordinary and they give good practical
                      training. They make to understand all the concepts in a
                      very easy way. One can find a job very easily if they
                      follow the words of the trainer. Trainers are very
                      dedicated, friendly and supportive. Would recommend it to
                      everyone who seeks a job in IT.
                    </i>
                  </p>
                  <div className="text-center start-rating">
                    <div className="d-flex justify-content-center">
                      <span>
                        <i class="ri-star-fill"></i>
                      </span>
                      <span>
                        <i class="ri-star-fill"></i>
                      </span>
                      <span>
                        <i class="ri-star-fill"></i>
                      </span>
                      <span>
                        <i class="ri-star-fill"></i>
                      </span>
                      <span>
                        <i class="ri-star-fill"></i>
                      </span>
                    </div>
                  </div>
                </div>

                <div className="text-center py-2">
                  <h1>
                    <i class="ri-user-line"></i>
                  </h1>
                  <h1>
                    <b>Padmapriya</b>
                  </h1>
                  <p>
                    <i>
                      The faculties are highly experienced and friendly. The
                      quality of teaching is excellent and the approach is very
                      practical. They have designed the syllabus for easy
                      learning and every aspect of the subject is covered with
                      real-time knowledge and problems. They train everyone
                      irrespective of their technical or non-technical knowledge
                      at their best. build us from the basics. Aimore is the
                      best choice for any course learning and it is more than
                      worth our time and money.
                    </i>
                  </p>
                  <div className="text-center start-rating">
                    <div className="d-flex justify-content-center">
                      <span>
                        <i class="ri-star-fill"></i>
                      </span>
                      <span>
                        <i class="ri-star-fill"></i>
                      </span>
                      <span>
                        <i class="ri-star-fill"></i>
                      </span>
                      <span>
                        <i class="ri-star-fill"></i>
                      </span>
                      <span>
                        <i class="ri-star-fill"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </div>

      <div class="edu-contact-us-area eduvibe-contact-us edu-section-gap bg-color-white">
        <div class="container eduvibe-animated-shape">
          <div class="row g-5">
            <div class="col-lg-12">
              <div
                class="section-title text-center sal-animate"
                data-sal-delay="150"
                data-sal="slide-up"
                data-sal-duration="800"
              >
                <h3 class="title">Get In Touch With us</h3>
              </div>
            </div>
          </div>
          <div class="row g-5 mt--20">
            <div class="col-lg-7">
              <div class="contact-info  pr_lg--0 pr_md--0 pr_sm--0">
                <div class="row g-5">
                  <div
                    class="col-lg-12 col-md-12 col-sm-12 col-12 sal-animate"
                    data-sal-delay="150"
                    data-sal="slide-up"
                    data-sal-duration="800"
                  >
                    <div class="contact-address-card-1 website">
                      <div class="inner">
                        <div class="content">
                          <h1 class="title">Medavakkam-Branch</h1>
                          <div className="d-flex align-items-center mb-4">
                            <div class="icon mb-2">
                              <i class="icon-map-pin-line"></i>
                            </div>
                            <p className="mx-3">
                              3/165, Sumangali Second floor, Perumbakkam Main
                              Road, Medavakkam, Chennai, Tamil Nadu 600100
                            </p>
                          </div>
                          <div className="d-flex align-items-center mb-4">
                            <div class="icon mb-2">
                              <i class="icon-Headphone"></i>
                            </div>
                            <p className="mx-3">
                              <Link to="tel:+91 9176333334">
                                +91 9176-333-334
                              </Link>
                              <br />
                              <Link to="tel:+91 9962698698">
                                +91 9962-698-698
                              </Link>
                            </p>
                          </div>

                          <div className="d-flex align-items-center mb-4">
                            <div class="icon mb-2">
                              <i class="icon-mail-open-line"></i>
                            </div>
                            <Link
                              className="mx-3"
                              to="mailto:info@aimoretechnologies.com"
                            >
                              info@aimoretechnologies.com
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-lg-12 col-md-12 col-sm-12 col-12 sal-animate"
                    data-sal-delay="150"
                    data-sal="slide-up"
                    data-sal-duration="800"
                  >
                    <div class="contact-address-card-1 website">
                      <div class="inner">
                        <div class="content">
                          <h1 class="title">Porur-Branch</h1>
                          <div className="d-flex align-items-center mb-4">
                            <div class="icon mb-2">
                              <i class="icon-map-pin-line"></i>
                            </div>
                            <p className="mx-3">
                              No 112/2, Door No 140/104, First Floor, Phase 1
                              Mount Poonamallee, Trunk Road, Porur, Chennai,
                              Tamil Nadu - 600116
                            </p>
                          </div>
                          <div className="d-flex align-items-center mb-4">
                            <div class="icon mb-2">
                              <i class="icon-Headphone"></i>
                            </div>
                            <p className="mx-3">
                              <Link to="tel:+91 9159333334">
                                +91 9159-333-334
                              </Link>
                              <br />
                              <Link to="tel:+91 9786400004">
                                +91 9786-400-004
                              </Link>
                            </p>
                          </div>

                          <div className="d-flex align-items-center mb-4">
                            <div class="icon mb-2">
                              <i class="icon-mail-open-line"></i>
                            </div>
                            <Link
                              className="mx-3"
                              to="mailto:info@aimoretechnologies.com"
                            >
                              info@aimoretechnologies.com
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-lg-12 col-md-12 col-sm-12 col-12 sal-animate"
                    data-sal-delay="150"
                    data-sal="slide-up"
                    data-sal-duration="800"
                  >
                    <div class="contact-address-card-1 website">
                      <div class="inner">
                        <div class="content">
                          <h1 class="title">Karapakkam-Branch</h1>
                          <div className="d-flex align-items-center mb-4">
                            <div class="icon mb-2">
                              <i class="icon-map-pin-line"></i>
                            </div>
                            <p className="mx-3">
                              1/16/2, Rajiv Gandhi Road (Old Mahabalipuram
                              Road), Karapakkam(N198), Chennai - 600097
                            </p>
                          </div>
                          <div className="d-flex align-items-center mb-4">
                            <div class="icon mb-2">
                              <i class="icon-Headphone"></i>
                            </div>
                            <p className="mx-3">
                              <Link to="tel:+91 9655333334">
                                +91 9655-333-334
                              </Link>
                              <br />
                              <Link to="tel:+91 9962898898">
                                +91 9962-898-898
                              </Link>
                            </p>
                          </div>

                          <div className="d-flex align-items-center mb-4">
                            <div class="icon mb-2">
                              <i class="icon-mail-open-line"></i>
                            </div>
                            <Link
                              className="mx-3"
                              to="mailto:info@aimoretechnologies.com"
                            >
                              info@aimoretechnologies.com
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-5">
              <h1 className="title py-3">Fill up the details</h1>
              <form
                onSubmit={sendEmail}
                ref={form}
                className="rnt-contact-form rwt-dynamic-form row"
              >
                <div className="form-group">
                  <input
                    type="text"
                    name="contact_name"
                    placeholder="Enter Name"
                    className="form-control"
                    required
                  />
                </div>
                <div className="form-group">
                  <input
                    type="email"
                    name="contact_email"
                    placeholder="Enter Email"
                    className="form-control"
                    required
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    name="contact_phone"
                    placeholder="Enter Phone"
                    className="form-control"
                    required
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    name="contact_course"
                    placeholder="Enter Course"
                    className="form-control"
                    required
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    name="contact_message"
                    placeholder="Enter Message"
                    className="form-control"
                    required
                  />
                </div>
                <div className="form-group" style={{ display: "none" }}>
                  <input
                    value={urls}
                    type="text"
                    name="contact_url"
                    placeholder="Enter URL"
                    className="form-control"
                  />
                </div>
                <div className="form-group">
                  <button
                    type="submit"
                    className="btn btn-primary rn-btn edu-btn w-100"
                  >
                    SUBMIT
                  </button>
                </div>
              </form>
              <h4>{message}</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
