import React from "react";

function Pagenotfound() {
  return (
    <div>
      <div className="py-5 mb-5 mt-5">
        <h1 className="notfoundhead">404</h1>
        <h1 className="text-center">page not found...</h1>
        <h1 className="notfiund text-center">
          <i class="ri-emotion-sad-line"></i>
        </h1>
      </div>
    </div>
  );
}

export default Pagenotfound;
