import React, { useEffect } from "react";
import Header from "./Pages/Header";
import Home from "./Pages/Home";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PowerBI from "./Pages/PowerBI";
import Selenium from "./Pages/Selenium";
import Aws from "./Pages/Aws";
import Python from "./Pages/Python";
import About from "./Pages/About";
import Contact from "./Pages/Contact";
import Footer from "./Pages/Footer";
import SnowflakeTraining from "./Pages/SnowflakeTraining";
import SoftwareTraining from "./Pages/SoftwareTraining";
import FullstackDeveloperCourse from "./Pages/FullstackDeveloperCourse";
import Pagenotfound from "./Pages/Pagenotfound";
import Iics from "./Pages/Iics";
import BestSoftwaretraining from "./Pages/Blogs/BestSoftwaretraining";
import Doyouwanttobecomesoftwaredeveloper from "./Pages/Blogs/Doyouwanttobecomesoftwaredeveloper";
import Blog from "./Pages/Blog";
import Topreasonstochooseinformaticaiics from "./Pages/Blogs/Topreasonstochooseinformaticaiics";
import BestSofwaretraininginstituteinchennai from "./Pages/Blogs/BestSofwaretraininginstituteinchennai";
import Howtofindoutthebestawstrainingcourseforbeginners from "./Pages/Blogs/Howtofindoutthebestawstrainingcourseforbeginners";
import Masteryourcareerpotentialwithaimoressoftwaretraining from "./Pages/Blogs/Masteryourcareerpotentialwithaimoressoftwaretraining";
import Gatewayforsuccessfullautomation from "./Pages/Blogs/Gatewayforsuccessfullautomation";
import Unlockinsightsaimorepowerbi from "./Pages/Blogs/Unlockinsightsaimorepowerbi";
import Learnpythoninjust2monthswithaimore from "./Pages/Blogs/Learnpythoninjust2monthswithaimore";
import Howtofindthebestsoftwaretraining from "./Pages/Blogs/Howtofindthebestsoftwaretraining";
import Aimoresfullstackdevelopercourse from "./Pages/Blogs/Aimoresfullstackdevelopercourse";
import Wheretogetthebestsnowflaketraining from "./Pages/Blogs/Wheretogetthebestsnowflaketraining";

function App() {
  useEffect(() => {}, []);

  return (
    <div>
      <Router basename="/">
        <Header />
        <div>
          <Routes>
            <Route exact path="/" element={<Home />}></Route>
            <Route path="/about-us" element={<About />}></Route>
            <Route path="/contact-us" element={<Contact />}></Route>
            <Route
              path="/power-bi-training-in-chennai/course-details.html/"
              element={<PowerBI to="/power-bi-training-in-chennai/" replace />}
            />
            <Route
              path="/power-bi-training-in-chennai/"
              element={<PowerBI />}
            ></Route>
            <Route
              path="/selenium-training-in-chennai/"
              element={<Selenium />}
            ></Route>
            <Route path="/aws-training-in-chennai/" element={<Aws />}></Route>
            <Route
              path="/python-training-in-chennai/"
              element={<Python />}
            ></Route>
            <Route
              path="/best-software-training-institute-in-chennai/"
              element={<SoftwareTraining />}
            ></Route>
            <Route
              path="/full-stack-developer-course-in-chennai/"
              element={<FullstackDeveloperCourse />}
            ></Route>
            <Route
              path="/informatica-iics-training-in-chennai/"
              element={<Iics />}
            ></Route>
            <Route
              path="/snowflake-training-in-chennai/"
              element={<SnowflakeTraining />}
            ></Route>
            <Route
              path="/blog/which-is-the-best-software-training-institute-in-2024/"
              element={<BestSoftwaretraining />}
            ></Route>
            <Route
              path="/blog/do-you-want-to-become-a-software-developer"
              element={<Doyouwanttobecomesoftwaredeveloper />}
            ></Route>
            <Route
              path="/blog/best-software-training-institute-in-chennai"
              element={<BestSofwaretraininginstituteinchennai />}
            ></Route>
            <Route
              path="/blog/how-to-find-out-the-best-aws-training-course-for-beginners"
              element={<Howtofindoutthebestawstrainingcourseforbeginners />}
            ></Route>
            <Route
              path="/blog/Master-your-career-potential-with-aimores-software-training"
              element={<Masteryourcareerpotentialwithaimoressoftwaretraining />}
            ></Route>
            <Route
              path="/blog/Your-gateway-for-the-best-successful-career-automation"
              element={<Gatewayforsuccessfullautomation />}
            ></Route>
            <Route
              path="/blog/Unlocking-insights-aimore-power-bi-training-in-chennai"
              element={<Unlockinsightsaimorepowerbi />}
            ></Route>
            <Route
              path="/blog/Learn-python-in-just-2-months-with-aimore-python-course-in-chennai"
              element={<Learnpythoninjust2monthswithaimore />}
            ></Route>
            <Route
              path="/blog/How-to-find-the-best-software-training"
              element={<Howtofindthebestsoftwaretraining />}
            ></Route>
            <Route
              path="/blog/Aimores-fullstack-developer-course"
              element={<Aimoresfullstackdevelopercourse />}
            ></Route>
            <Route
              path="/blog/Where-to-get-the-best-snowflake-training"
              element={<Wheretogetthebestsnowflaketraining />}
            ></Route>
            <Route
              path="/blog/Top-reasons-to-choose-informatica-iics-training"
              element={<Topreasonstochooseinformaticaiics />}
            ></Route>
            <Route path="/blog" element={<Blog />}></Route>
            <Route path="*" element={<Pagenotfound />} />
          </Routes>
        </div>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
