import React from "react";
import bestsoftware from "../assets/images/blog/best-software-training-in-chennai.webp";
import doyouwant from "../assets/images/blog/do-you-want-to-become-software-developer.webp";
import bestsoftwareplacement from "../assets/images/blog/Best-Software-Training-Institute-In-Chennai-With-Placement.webp";
import { Link } from "react-router-dom";
import howtofind from "../assets/images/blog/How-To-Find-Out-The-Best-AWS-Training-Course-For-Beginners.webp";
import masteryourcareer from "../assets/images/blog/Master-Your-Career-Potential-With-Aimore's-Software-Training-Course-In-Chennai.webp";
import yourgateway from "../assets/images/blog/Your-Gateway-for -The-Best-Successful- Career- Automation.webp";
import unlockinginsights from "../assets/images/blog/Unlocking-Insights-Aimore-Power-BI- Training-in-Chennai.webp";
import learnpython from "../assets/images/blog/Learn-Python-in-Just-2- Months-with-Aimore-Python- Course-in-Chennai.webp";
import bestsoftwaretraining from "../assets/images/blog/How-to-find-the-best-software-training.webp";
import aimoresfullstack from "../assets/images/blog/Aimore's-fullstack-developer-course.webp";
import wheretogetthebestsnowflake from "../assets/images/blog/Where-To-Get-The-Best-Snowflake-Training-in-Chennai.webp";
import topreasonstochooseinformatica from "../assets/images/blog/Top-Reasons-to-Choose -Informatica -IICS -Training-in -Chennai.webp";

import "./Blogs/CSS/Blogstyle.css";
function Blog() {
  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-md-10 offset-md-1 py-3">
            <h1 className="text-center py-3">Our Latest Blog</h1>
            <div className="row flex-row-rev colum-rev">
              <div className="col-md-4">
                <h2> Which Is The Best Software Training Institute In 2024?</h2>
                <img src={bestsoftware} alt="" className="img-fluid" />
                <Link
                  className="btn btn-primary customreadmore"
                  to="/blog/which-is-the-best-software-training-institute-in-2024/"
                >
                  Read More
                </Link>
              </div>
              <div className="col-md-4">
                <h2> Do You Want To Become A Software Developer?</h2>
                <img src={doyouwant} alt="" className="img-fluid" />
                <Link
                  className="btn btn-primary customreadmore"
                  to="/blog/do-you-want-to-become-a-software-developer"
                >
                  Read More
                </Link>
              </div>
              <div className="col-md-4">
                <h2>
                  Best Software Training Institute In Chennai With Placement
                </h2>
                <img src={bestsoftwareplacement} alt="" className="img-fluid" />
                <Link
                  className="btn btn-primary customreadmore"
                  to="/blog/best-software-training-institute-in-chennai"
                >
                  Read More
                </Link>
              </div>
              <div className="col-md-4">
                <h2>
                  How To Find Out The Best AWS Training Course For Beginners?
                </h2>
                <img
                  src={howtofind}
                  alt="How-To-Find-Out-The-Best-AWS-Training-Course-For-Beginners"
                  className="img-fluid"
                />
                <Link
                  className="btn btn-primary customreadmore"
                  to="/blog/how-to-find-out-the-best-aws-training-course-for-beginners"
                >
                  Read More
                </Link>
              </div>
              <div className="col-md-4">
                <h2>Your Gateway for The Best Successful Career Automation</h2>
                <img
                  src={yourgateway}
                  alt="Your-Gateway-for-The-Best-Successful-Career-Automation"
                  className="img-fluid"
                />
                <Link
                  className="btn btn-primary customreadmore"
                  to="/blog/Your-gateway-for-the-best-successful-career-automation"
                >
                  Read More
                </Link>
              </div>
              <div className="col-md-4">
                <h2>
                  Master Your Career Potential With Aimore's Software Training
                  Course In Chennai
                </h2>
                <img
                  src={masteryourcareer}
                  alt="Master-Your-Career-Potential-With-Aimores-Software-Training-Course-In-Chennai"
                  className="img-fluid"
                />
                <Link
                  className="btn btn-primary customreadmore"
                  to="/blog/Master-your-career-potential-with-aimores-software-training"
                >
                  Read More
                </Link>
              </div>
              <div className="col-md-4">
                <h2>
                  Unlocking Insights: Aimore - Power BI Training in Chennai for
                  Data-Driven Success
                </h2>
                <img
                  src={unlockinginsights}
                  alt="Unlocking-Insights-Aimore-Power-BI-Training-in-Chennai"
                  className="img-fluid"
                />
                <Link
                  className="btn btn-primary customreadmore"
                  to="/blog/Unlocking-insights-aimore-power-bi-training-in-chennai"
                >
                  Read More
                </Link>
              </div>
              <div className="col-md-4">
                <h2>
                  Learn Python in Just 2 Months with Aimore Python Course in
                  Chennai
                </h2>
                <img
                  src={learnpython}
                  alt="Learn-python-in-just-2-months-with-aimore-python-course-in-chennai"
                  className="img-fluid"
                />
                <Link
                  className="btn btn-primary customreadmore"
                  to="/blog/Learn-python-in-just-2-months-with-aimore-python-course-in-chennai"
                >
                  Read More
                </Link>
              </div>
              <div className="col-md-4">
                <h2>How to Find the Best Software Training in Chennai?</h2>
                <img
                  src={bestsoftwaretraining}
                  alt="How-to-find-the-best-software-training"
                  className="img-fluid"
                />
                <Link
                  className="btn btn-primary customreadmore"
                  to="/blog/How-to-find-the-best-software-training"
                >
                  Read More
                </Link>
              </div>
              <div className="col-md-4">
                <h2>
                  Aimore's Full Stack Developer Course Will Help You To Become
                  From Zero to Hero.
                </h2>
                <img
                  src={aimoresfullstack}
                  alt="Aimore's-fullstack-developer-course.webp"
                  className="img-fluid"
                />
                <Link
                  className="btn btn-primary customreadmore"
                  to="/blog/Aimores-fullstack-developer-course"
                >
                  Read More
                </Link>
              </div>
              <div className="col-md-4">
                <h2>Where To Get The Best Snowflake Training in Chennai?</h2>
                <img
                  src={wheretogetthebestsnowflake}
                  alt="Where-to-get-the-best-snowflake-training"
                  className="img-fluid"
                />
                <Link
                  className="btn btn-primary customreadmore"
                  to="/blog/Where-to-get-the-best-snowflake-training"
                >
                  Read More
                </Link>
              </div>
              <div className="col-md-4">
                <h2>
                  Top Reasons to Choose Informatica IICS Training in Chennai
                  From Aimore!
                </h2>
                <img
                  src={topreasonstochooseinformatica}
                  alt="Top-reasons-to-choose-informatica-iics-training"
                  className="img-fluid"
                />
                <Link
                  className="btn btn-primary customreadmore"
                  to="/blog/Top-reasons-to-choose-informatica-iics-training"
                >
                  Read More
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Blog;
